import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import GLightbox from 'glightbox';

import { $Elements, BrowserEvents } from './constants';
import { fixMobileHover, initFacebookPixel, initGoogleAnalytics, setCookiesAllowed, setDisclaimerAccepted } from './helpers';
import { calculateMenuOffset } from './menu';

// ---------- LazyLoad Config ----------

lazySizes.cfg.throttleDelay = 66;
lazySizes.cfg.preloadAfterLoad = true;

// ---------- jQuery Related ----------

;(function($) {

	$Elements.document.on(BrowserEvents.page.enter, () => {

		// ---------- Fix VH ----------

		$Elements.window.on('resize', () => document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`));
		$Elements.window.trigger('resize');

		// ---------- Fix Mobile Hover ----------

		fixMobileHover();

		// ---------- Links ----------

		$('a[href="#"], .void-link').click((e) => e.preventDefault());

		$('[data-scroll-to]').click(function(e) {
			e.preventDefault();

			const self = $(this);
			const target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

			if(target.length) {

				$('html, body').stop().animate({
					scrollTop: calculateMenuOffset(target)
				}, 1000);
			}
		});

		$('.select-dropdown .dropdown-menu .btn').click(function() {
			$(this).closest('.dropdown').find('.dropdown-toggle').html($(this).text());
		});


		// ---------- Line Scroll To ----------

		$('.btn-scroll').click(function(e) {
			e.preventDefault();

			if($('body').hasClass('home')) {

				$('html, body').stop().animate({
					scrollTop: $('section.home').innerHeight(),
				}, 1000);

			}

		});

		// ---------- GLightbox ----------

		$('[target="lightbox"]').each(function() {

			GLightbox({
				selector: 'a[href="' + this.attributes.href.value + '"][target="lightbox"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				videosWidth: 1280,
			});
		});

		$('[target^="lightbox-gallery"]').each(function() {

			GLightbox({
				selector: 'a[target="' + this.attributes.target.value + '"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				videosWidth: 1280,
			});
		});

		// ---------- Disclaimer ----------

		$('#disclaimer').on('closed.bs.alert', function() {

			setDisclaimerAccepted();
			$Elements.body.removeClass('disclaimer-open');

		});

		// ---------- Cookies ----------

		$('#cookies-allowed').on('click', '[data-dismiss="alert"]', function() {
			setCookiesAllowed(this.dataset.allowed);
			$Elements.window.trigger('cookies-allowed');
		});

		$Elements.window.on('cookies-allowed', function() {
			initGoogleAnalytics();
			initFacebookPixel();
		});

		initGoogleAnalytics();
		initFacebookPixel();

	});

}(jQuery));
