import { $Elements, BrowserEvents, TransitionDurations } from './constants';
import { Autoplay, Navigation, Swiper, EffectFade } from 'swiper';

Swiper.use([Autoplay, Navigation, EffectFade]);

;(function($) {

	$Elements.document.on(BrowserEvents.page.enter, () => {

		// ---------- Masonry ----------

		$('.content-section .section-masonry').each(function() {
			const self = $(this);

			self.isotope({
				masonry: {
					percentPosition: true
				},
				itemSelector: ".single-column"
			})
		});

		// ---------- Default Carousel ----------

		// $('.content-section .section-carousel, .content-section .column-carousel').each(function() {
		//
		// 	if(!this.swiper) {
		//
		// 		const swiper = new Swiper(this, {
		// 			speed: TransitionDurations.default,
		// 			loop: true,
		// 			observer: true,
		// 			pagination: {
		// 				el: this.querySelector('.swiper-pagination'),
		// 			},
		// 			navigation: {
		// 				nextEl: this.querySelector('.swiper-button-next'),
		// 				prevEl: this.querySelector('.swiper-button-prev'),
		// 			},
		// 		});
		// 	}
		// });

		// ---------- Images Slideshow ----------

		$('.images-carousel-section .column-slideshow').each(function() {

			if(!this.swiper) {

				const swiper = new Swiper(this, {
					speed: TransitionDurations.default,
					loop: true,
					observer: true,
					centeredSlides: true,
					spaceBetween: 20,
					slidesPerView: 'auto',
					pagination: {
						el: this.querySelector('.swiper-pagination'),
					},
					navigation: {
						nextEl: this.querySelector('.swiper-button-next'),
						prevEl: this.querySelector('.swiper-button-prev'),
					},
				});
			}
		});

		// ---------- Text-Image Slideshow ----------

		$('.text-image-carousel-section .section-carousel').each(function() {

			if(!this.swiper) {

				const swiper = new Swiper(this, {
					speed: TransitionDurations.default,
					loop: true,
					observer: true,
					effect: 'fade',
					pagination: {
						el: this.querySelector('.swiper-pagination'),
					},
					navigation: {
						nextEl: this.querySelector('.swiper-button-next'),
						prevEl: this.querySelector('.swiper-button-prev'),
					},
				});
			}
		});

	});

}(jQuery));
