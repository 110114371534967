import $ from 'jquery';
import Cookies from 'js-cookie';

export const prefixEvent = (event, timing) => {
	const el = document.createElement('el');

	const prefixes = ['webkit', 'moz', 'MS', 'o', ''];
	let events = {};

	for(let prefix of prefixes) {
		events[prefix + event] = prefix + event + timing;
	}

	for(let e in events) {
		if(typeof el.style[e] !== undefined) {
			return events[e];
		}
	}
};

export const getCSSVariable = (variable, element = document.documentElement) => getComputedStyle(element).getPropertyValue(variable);

export const wait = (delay, value) => new Promise(resolve => setTimeout(resolve, delay, value));

export function escapeHTML(strings) {

	let result = strings[0];

	for(let i = 1; i < arguments.length; i++) {
		result += String(arguments[i]).replace(/[&<>'"]/g, (c) => {
			return { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#39;' }[c];
		});
		result += strings[i];
	}

	return result;
}

export function fixMobileHover() {

	if(!!('ontouchstart' in window) || !!('msmaxtouchpoints' in window.navigator)) {
		for(let sheetI = document.styleSheets.length - 1; sheetI >= 0; sheetI--) {
			let sheet = document.styleSheets[sheetI];
			if(sheet.cssRules) {
				for(let ruleI = sheet.cssRules.length - 1; ruleI >= 0; ruleI--) {
					let rule = sheet.cssRules[ruleI];
					if(rule.selectorText) {
						rule.selectorText = rule.selectorText.replace(':hover', ':active');
					}
				}
			}
		}
	}
}

export const mediaQueryString = (breakpoint, min = true) => (min) ? `(min-width: ${breakpoint}px)` : `(max-width: ${breakpoint - 1}px)`;

export function mediaQuery(breakpoint, before, after) {

	const handler = (query) => {
		if(typeof after === 'function' && query.matches) {
			after();
		}
		else if(typeof before === 'function') {
			before();
		}
	};

	if(window.matchMedia) {
		const query = window.matchMedia(mediaQueryString(breakpoint));
		query.addListener(handler);
		handler(query);
	}
}

export function scrollPosition() {

	const root = document.documentElement;
	const body = document.body;

	const scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
	const scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;

	const clientTop = root.clientTop || body.clientTop || 0;
	const clientLeft = root.clientLeft || body.clientLeft || 0;

	return {
		scrollTop: scrollTop,
		scrollLeft: scrollLeft,
		clientTop: clientTop,
		clientLeft: clientLeft
	};
}

export function elementPosition(element) {

	const rect = element.getBoundingClientRect();

	const scroll = scrollPosition();

	return {
		top: Math.round(rect.top + scroll.scrollTop - scroll.clientTop),
		left: Math.round(rect.left + scroll.scrollLeft - scroll.clientLeft),
		height: rect.height,
		width: rect.width,
	};
}

export function replaceBodyClasses(next) {

	const parser = new DOMParser();
	const newPage = parser.parseFromString(next.html, "text/html");
	const newBody = newPage.querySelector('body');

	document.body.className = newBody.getAttribute('class');

}

export function initGoogleAnalytics() {

	console.log(tracking_sites_params);

	if(isCookiesAllowed() && typeof tracking_sites_params !== 'undefined' && tracking_sites_params.google_analytics.active) {

		if(typeof gtag === 'function') {
			gtag('config', tracking_sites_params.google_analytics.id, {
				'page_title': document.title,
				'page_path': window.location.pathname
			});
		}
		else {

			!function(w, d, s, i) {
				w.dataLayer = w.dataLayer || [];
				w.gtag = function() {
					dataLayer.push(arguments);
				}
				let f = d.getElementsByTagName(s)[0],
					j = d.createElement(s);
				j.async = true;
				j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i;
				f.parentNode.insertBefore(j, f);
			}(window, document, 'script', tracking_sites_params.google_analytics.id);

			gtag('js', new Date());
			gtag('config', tracking_sites_params.google_analytics.id, {
				'page_title': document.title,
				'page_path': window.location.pathname
			});
		}
	}
}

export function initFacebookPixel() {

	if (isCookiesAllowed() && typeof tracking_sites_params !== 'undefined' && tracking_sites_params.facebook_pixel.active) {

		if (typeof fbq === 'function') {
			fbq('init', tracking_sites_params.facebook_pixel.id);
			fbq('track', 'PageView');
		} else {

			!function (f, b, e, v, n, t, s) {
				if (f.fbq) return;
				n = f.fbq = function () {
					n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
				};
				if (!f._fbq) f._fbq = n;
				n.push = n;
				n.loaded = !0;
				n.version = '2.0';
				n.queue = [];
				t = b.createElement(e);
				t.async = !0;
				t.src = v;
				s = b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t, s)
			}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

			fbq('init', tracking_sites_params.facebook_pixel.id);
			fbq('track', 'PageView');
		}
	}
}

export function ajax(options = {}) {

	return new Promise((resolve, reject) => {

		jQuery.ajax(options).done(response => {

			if(response.success) {
				resolve(response);
			}
			else {
				console.error(response);
				reject(response);
			}
		}).fail(error => {
			console.error(error);
			reject(error);
		});
	});
}

export function setDisclaimerAccepted() {

	Cookies.set('tc-disclaimer-accepted', true, {
		expires: 365,
		path: '/',
	});
}

export function setCookiesAllowed(consent) {

	Cookies.set('tc-cookies-allowed', consent, {
		expires: 365,
		path: '/',
	});
}

export function isCookiesAllowed() {
	return Cookies.get('tc-cookies-allowed') === 'true';
}


(r => r.keys().map(r))(require.context('../../svg/', true));

(r => r.keys().map(r))(require.context('../../images/', true, /\.(gif|png|jpe?g|svg)$/));
