import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { $Elements, Breakpoints, BrowserEvents, Classes } from '../global/constants';
import { mediaQueryString } from '../global/helpers';

gsap.registerPlugin(ScrollTrigger);

;(function($) {

	$Elements.document.on(BrowserEvents.page.enter, () => {

		// ---------- Variables ----------

		let mobileAnimations = [];
		let desktopAnimations = [];
		let globalAnimations = [];

		// ---------- Animated Section ----------

		$('.animated-section:not(.transition-clone)').each(function() {

			globalAnimations.push(() => {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						once: true,
						start: 'top 80%',
						toggleClass: Classes.active,
					}
				});
			});
		});

		// ---------- Parallax Section ----------

		$('.parallax-section:not(.fixed-parallax-section):not(.transition-clone)').each(function() {

			desktopAnimations.push(() => {

				const image = this.querySelector(':scope .section-image');
				const speed = 25;

				if(image) {

					const timeline = gsap.timeline({
						scrollTrigger: {
							trigger: this,
							start: 'top bottom',
							end: 'bottom top',
							scrub: true,
						},
					});

					timeline.fromTo(image, { yPercent: -speed }, { yPercent: speed, ease: 'none' });
				}
			});
		});

		// ---------- Parallax Circle (background) ----------

		$('.page-wrapper').each(function() {

			desktopAnimations.push(() => {

				const image = this.querySelector('.giant-circle');
				const speed = -50;

				if(image) {

					const timeline = gsap.timeline({
						scrollTrigger: {
							trigger: this,
							start: 'top bottom',
							end: 'bottom top',
							scrub: true,
						},
					});

					timeline.fromTo(image, { yPercent: -speed }, { yPercent: speed, ease: 'none' });
				}
			});
		});

		// ---------- Handle Media Queries ----------

		ScrollTrigger.matchMedia({

			// Desktop Animations
			[mediaQueryString(Breakpoints.Mobile)]: () => desktopAnimations.map(animation => animation()),

			// Mobile Animations
			[mediaQueryString(Breakpoints.Mobile, false)]: () => mobileAnimations.map(animation => animation()),

			// Global Animations
			'all': () => globalAnimations.map(animation => animation()),

		});

	});

}(jQuery));
